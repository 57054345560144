import React, {useState} from 'react';
import LeanTransformation from '../../Component/LeanTransformation/LeanTransformation';


const LeanTransformationPage = () => {

    
    return (
        <div>
            <LeanTransformation/>
        </div>
    );
};

export default LeanTransformationPage;
const industries = [
    {
        "id": 1,
        "name": 'Apparel'
    },
    {
        "id": 2,
        "name": "Automobile"
    },
    {
        "id": 3,
        "name": "Bank" 
    },
    {
        "id": 4,
        "name": "Cement"
    },
    {
        "id": 5,
        "name": "Constructions"
    },
    {
        "id": 6,
        "name": "Electronics"
    },
    {
        "id": 7,
        "name": "FMCG"
    },
    {
        "id": 8,
        "name": "Food"
    },
    {
        "id": 9,
        "name": "GEO Textile"
    },
    {
        "id": 10,
        "name": "Iron"
    },
    {
        "id": 11,
        "name": "IT"
    },
    {
        "id": 12,
        "name": "Leather And Footwear"
    },
    {
        "id": 13,
        "name": "Mobile"
    },
    {
        "id": 14,
        "name": "Packaging"
    },
    {
        "id": 15,
        "name": "Pharma And Medicine"
    },
    {
        "id": 16,
        "name": "Poly Fiber"
    }
]

export default industries
import React from 'react';
import ContactUs from '../../../Component/ContactUs/ContactUs';

const Contact = () => {
    return (
        <div>
            <ContactUs/>
        </div>
    );
};

export default Contact;
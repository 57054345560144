const msi = [
    {
        id: 1,
        title : "Strategy Deployment & KPI"
    },
    {
        id: 2,
        title : "Multi-level MDI Approach"
    },
    {
        id: 3,
        title : "CI Alignment & Knowlegde Sharing"
    },
    {
        
        id: 4,
        title : "Deploy solutions web-based tools"
    }
]

export default msi
const programmes = [
  {
    id: 1,
    img: "https://i.ibb.co/cTsvKvT/confidence.jpg",
    name: "Long Term Lean Transformation Consulting",
    companyName: "Confidence Batteries Limited",
  },
  {
    id: 2,
    img: "https://i.ibb.co/ZX8n2Rw/square.jpg",
    name: "Training of Trainers",
  },
  {
    id: 3,
    img: "https://i.ibb.co/yd1Syrt/r.jpg",
    name: "Training of Trainers",
  },
  {
    id: 4,
    img: "https://i.ibb.co/txDkWqQ/Acme.jpg",
    name: "Session in The ACME Laboratories Ltd.",
  },

  {
    id: 6,
    img: "https://i.ibb.co/rkm7PhC/Acmes.jpg",
    name: "Training of Lean Manufacturing System",
  },
  {
    id: 7,
    img: "https://i.ibb.co/JcFms4S/k-line.jpg",
    name: "Training Session on “Team Work in Cultural Diversity”",
  },
  {
    id: 8,
    img: "https://i.ibb.co/NNgzHM0/ram.jpg",
    name: "Lean Manufacturing with the Students of 14th Batch of PGDPQM”",
  },
  {
    id: 9,
    img: "https://i.ibb.co/swmv4bD/lean.jpg",
    name: "Session on “Lean Manufacturing” for the students of SEIP- GB ",
  },
  {
    id: 10,
    img: "https://i.ibb.co/16GG6pH/smc.jpg",
    name: "Lean Manufacturing at SMC Enterprise Limited",
  },
  {
    id: 11,
    img: "https://i.ibb.co/MgbvhDD/MNC.jpg",
    name: "Session on “Executive Presence” with the Mid Level Leaders of one of the Most Reputable MNC",
  },
];

export default programmes;

import React from 'react';
import Testimonial from '../../Component/Testimonial/Testimonial';

const TestimonialPage = () => {
    return (
        <div>
            <Testimonial/>
        </div>
    );
};

export default TestimonialPage;
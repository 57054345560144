import React from 'react';
import Programmes from '../../Component/Programmes/Programmes';

const Programme = () => {
    return (
        <div>
            <Programmes/>
        </div>
    );
};

export default Programme;
import React from 'react';
import SeedComponent from '../../Component/SeedComponent/SeedComponent';

const Seed = () => {
    return (
        <div>
            <SeedComponent/>
        </div>
    );
};

export default Seed;
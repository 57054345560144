import React from 'react';
import Blog from '../../Component/Blog/Blog';

const BlogPage = () => {
    return (
        <div>
            <Blog/>
        </div>
    );
};

export default BlogPage;